import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  createContext,
  useContext,
  Suspense,
} from 'react';
import {
  Search,
  BookOpen,
  Loader2,
  AlertCircle,
  Clock,
  ArrowLeft,
  Share2,
  Copy,
  Sun,
  Moon,
  Lightbulb,
  CheckCircle,
  AlertTriangle,
  ChevronDown,
  ChevronUp,
  ArrowUpCircle,
  Mail,
  Scale,
  ExternalLink,
  MessageCircle,
  Database,
  Code2,
  Paintbrush,
  Component,
  Info,
  Link,
  Minus, 
  Plus, 
  X,
  ZoomIn,
  ZoomOut,
  RotateCcw
} from 'lucide-react';

import './mermaid.css'; // Import your custom CSS file
import { motion, AnimatePresence } from 'framer-motion';
import * as Dialog from '@radix-ui/react-dialog';
import * as Tooltip from '@radix-ui/react-tooltip';
import { FaLinkedin } from 'react-icons/fa';
import PropTypes from 'prop-types';
import mermaid from 'mermaid';

// Simplified ImageModal component:
const ImageModal = ({ isOpen, onClose, imageUrl, title, pageNumber, quote, highlight }) => {
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setImageError(false);
      setIsLoading(true);
    }
  }, [isOpen]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
        <Dialog.Content
          className="fixed inset-4 sm:inset-auto sm:left-1/2 sm:top-1/2 sm:max-w-4xl sm:w-full sm:-translate-x-1/2 sm:-translate-y-1/2 bg-white dark:bg-gray-900 rounded-lg shadow-xl z-50 p-4"
          style={{
            maxHeight: "90vh",
            maxWidth: "95vw",
          }}
          aria-describedby="image-modal-description"
        >
          <div className="h-full flex flex-col">
            <div className="flex justify-between items-center mb-4">
              <Dialog.Title className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                {title} - Page {pageNumber}
              </Dialog.Title>
              <button
                onClick={onClose}
                className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
                aria-label="Close modal"
              >
                <X className="w-5 h-5 text-gray-900 dark:text-gray-100" /> 
              </button>
            </div>

            <div
              className="flex-1 overflow-auto relative bg-gray-100 dark:bg-gray-800 rounded-lg"
              style={{
                maxHeight: "calc(80vh - 6rem)", // Adjust as needed
                padding: "1rem",
              }}
            >
              <div className="flex items-center justify-center min-h-full">
                {isLoading && !imageError && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-100 dark:bg-gray-800">
                    <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                  </div>
                )}

                {imageError ? (
                  <div className="text-gray-600 dark:text-gray-400 flex items-center gap-2">
                    <AlertCircle className="w-5 h-5" />
                    Failed to load image. Please try again later.
                  </div>
                ) : (
                  <img
                    src={`https://legal-oracle-screenshots.s3.ap-southeast-2.amazonaws.com/screenshots/${imageUrl}`}
                    alt={`${title} page ${pageNumber}`}
                    className="max-w-full max-h-full h-auto object-contain"
                    onError={() => {
                      setImageError(true);
                      setIsLoading(false);
                    }}
                    onLoad={() => setIsLoading(false)}
                    style={{ display: isLoading ? 'none' : 'block' }}
                  />
                )}
                
                {/* Highlight Box */}
                {highlight && !isLoading && !imageError && (
                  <div
                    className="absolute bg-yellow-400/30 border-2 border-yellow-400 pointer-events-none"
                    style={{
                      top: `${highlight.top}%`,
                      left: `${highlight.left}%`,
                      width: `${highlight.width}%`,
                      height: `${highlight.height}%`,
                    }}
                  />
                )}
              </div>
            </div>

            {/* Screen Reader Description and Quote */}
            <div id="image-modal-description" className="sr-only">
              Image viewer for {title} page {pageNumber}. {quote && `Showing quote: ${quote}`}
            </div>

            {quote && (
              <div className="mt-4 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
                <p className="text-sm text-gray-700 dark:text-gray-300 italic">
                  "{quote}"
                </p>
              </div>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};



const MermaidConfig = () => {
  const { theme } = useTheme();
  
	useEffect(() => {
	  mermaid.initialize({
		startOnLoad: true,
		theme: theme === 'dark' ? 'dark' : 'base',
		securityLevel: 'loose',
		fontFamily: 'inherit',
		flowchart: {
		  htmlLabels: true,
		  padding: 20,
		  curve: 'basis',
		},
		sequence: {
		  mirrorActors: false,
		},
		gantt: {
		  axisFormatter: [],
		},
		class: {},
		// Enable zoom and pan
		zoom: {
		  enabled: true,
		  scaleExtent: [0.5, 3],
		  useTransition: true,
		},
		maxTextSize: 100000,
	  });
	}, [theme]);

  return null;
};

// Create Theme and FontSize Context
const ThemeContext = createContext();
const FontSizeContext = createContext();

// Custom Hooks
const useTheme = () => useContext(ThemeContext);
const useFontSize = () => useContext(FontSizeContext);

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error('ErrorBoundary caught an error', error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center py-8">
          <AlertCircle className="h-8 w-8 text-red-600 mx-auto mb-4" aria-hidden="true" />
          <p className="text-red-700">Something went wrong. Please try again later.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

// Utility function to sanitize inputs
const sanitizeInput = (input) => {
  const div = document.createElement('div');
  div.textContent = input;
  return div.innerHTML;
};

// Constants
const JURISDICTIONS = [
  {
    name: 'South Australia',
    code: 'SA',
    available: true,
    acts: 569,
    regulations: 469,
    questions: [
      "What are the tenant's rights when ending a rental agreement?",
      "How much notice must a landlord give before raising rent?",
      "What constitutes workplace harassment?",
      "What are the legal requirements for forming a business partnership?",
      "How can I file a consumer complaint against a business?",
      "What are the penalties for driving without a valid license?",
      "How does parental leave work under local labor laws?",
      "What are the building permit requirements for home renovations?",
      "How are inheritance disputes resolved?",
      "What are the privacy obligations for businesses collecting customer data?",
      "What steps must be taken to challenge a speeding ticket?",
      "What is considered unfair dismissal in employment law?",
      "What are the age restrictions for alcohol purchase and consumption?",
      "What is the process for filing a police report for stolen property?",
      "What are the environmental protection laws for residential properties?",
      "What legal protections exist for small businesses in disputes?",
      "What are the regulations around public protests or demonstrations?",
      "How do local laws regulate pet ownership and animal welfare?",
      "What are the legal requirements for starting a home-based business?",
      "What are the penalties for illegal dumping or littering?",
      "How can I request access to public records from the government?",
      "What legal obligations do employers have regarding workplace safety?",
      "What are the rules for installing surveillance cameras at home?",
      "What are the restrictions on advertising and signage for businesses?",
      "How do local councils regulate noise complaints from neighbors?"
    ],
  },
  {
    name: 'Victoria',
    code: 'VIC',
    available: true,
    acts: 777,
    regulations: 408,
    questions: [
      "What are the rights of tenants when a property is sold?",
      "How do I contest a parking fine in my local area?",
      "What are the child custody arrangements after a divorce?",
      "How is workplace discrimination defined under labor law?",
      "What are the legal requirements for hosting public events?",
      "How can I apply for a restraining order?",
      "What are the obligations for landlords regarding home repairs?",
      "How are consumer warranties enforced for defective products?",
      "What are the limits on political campaign advertising?",
      "How are penalties for breaches of environmental regulations determined?",
      "What are the rules for setting up a charity or nonprofit organization?",
      "How do I report fraud or financial crime?",
      "What legal protections exist for customers purchasing online goods?",
      "What are the zoning regulations for building commercial properties?",
      "How is domestic violence legally defined and prosecuted?",
      "What are the rights of employees under minimum wage laws?",
      "What are the rules for registering a new vehicle?",
      "What are the obligations of companies under data breach notification laws?",
      "How do I challenge a court decision in a civil lawsuit?",
      "What legal requirements apply to setting up an import/export business?",
      "What is considered trespassing on private property?",
      "What are the reporting requirements for financial institutions?",
      "What are the penalties for unauthorized building extensions?",
      "How are disputes over shared property boundaries resolved?",
      "What rules apply to advertising alcohol products?"
    ],
  },
  {
    name: 'Commonwealth (Federal)',
    code: 'CWTH',
    available: true,
    acts: 4636,
    regulations: 0,
    questions: [
      "How is tax fraud investigated by federal authorities?",
      "What are the regulations for international trade and imports?",
      "What are the consumer rights when dealing with faulty electronics?",
      "How is anti-money laundering enforced at the federal level?",
      "What are the requirements for Australian citizenship?",
      "What are the privacy rights under the national Privacy Act?",
      "How are international business contracts legally enforced?",
      "What rules apply to cross-border banking services?",
      "How can I apply for federal government grants for businesses?",
      "What legal protections exist for whistleblowers in government agencies?",
      "What are the rules for foreign investments in local companies?",
      "How does copyright law protect creative works in Australia?",
      "What are the laws governing national security and surveillance?",
      "How are disputes over intellectual property resolved?",
      "What federal standards apply to aviation and airline safety?",
      "What are the penalties for violating customs regulations?",
      "How are family law disputes involving multiple states resolved?",
      "What are the rules for exporting agricultural products?",
      "What are the protections for employees under federal labor law?",
      "How do national regulations address digital privacy concerns?",
      "What legal standards apply to medical product testing and approval?",
      "What are the tax reporting obligations for online businesses?",
      "How are cross-border child custody disputes resolved?",
      "What are the penalties for violations of anti-discrimination laws?",
      "What federal legal protections exist for online consumer fraud?"
    ],
  }
];

const MAX_HISTORY = 10;
const MAX_SUGGESTIONS = 5;

// DisclaimerModal Component
const DisclaimerModal = ({ isOpen, onClose }) => {
  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[500px]
          transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white dark:bg-gray-900 border
          border-gray-300 dark:border-gray-700 p-6 shadow-lg z-50 overflow-y-auto focus:outline-none"
          aria-describedby="disclaimer-description"
        >
          <Dialog.Title className="text-xl font-bold text-red-600 dark:text-red-500 flex items-center gap-2 mb-4">
            <AlertCircle className="h-5 w-5" aria-hidden="true" />
            Important Notice
          </Dialog.Title>

          <div className="space-y-4">
            <div className="bg-yellow-100 dark:bg-yellow-900/30 border border-yellow-300 dark:border-yellow-700/50 rounded-lg p-4">
              <p className="text-yellow-900 dark:text-yellow-200 text-sm" id="disclaimer-description">
                This is an AI-powered tool providing information about legislation.
                It is not a substitute for professional legal advice.
              </p>
            </div>

            <div className="space-y-2">
              <h3 className="font-semibold text-gray-800 dark:text-gray-200">
                By using this service, you acknowledge:
              </h3>
              <ul className="space-y-2 text-sm text-gray-800 dark:text-gray-300">
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">
                    •
                  </span>
                  This is a research tool only, not a source of legal advice
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">
                    •
                  </span>
                  Information may be incomplete or outdated
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">
                    •
                  </span>
                  You should consult qualified legal professionals for specific advice
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">
                    •
                  </span>
                  No responsibility is taken for decisions made based on this information
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-6 space-y-4">
            <button
              onClick={onClose}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              I Understand and Accept
            </button>
            <p className="text-xs text-gray-700 dark:text-gray-400 text-center">
              Created by Hamish Cameron
            </p>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

DisclaimerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

// AboutModal Component
const AboutModal = ({
  isOpen,
  onClose,
  selectedJurisdictionName,
  selectedJurisdictionAvailable,
  selectedJurisdictionCode,
}) => {
  // Define official resources based on jurisdiction code
  const officialResources = {
    SA: {
      legislation: 'https://www.legislation.sa.gov.au',
      regulations: 'https://www.legislation.sa.gov.au',
    },
    VIC: {
      legislation: 'https://www.legislation.vic.gov.au',
      regulations: 'https://www.legislation.vic.gov.au',
    },
	CWTH: {
	  legislation: 'https://www.legislation.gov.au',
	  regulations: 'https://www.legislation.gov.au',
	},
  };

  const officialResourceLinks = officialResources[selectedJurisdictionCode];

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[600px]
          transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white dark:bg-gray-900 border
          border-gray-300 dark:border-gray-700 p-6 shadow-lg z-50 overflow-y-auto focus:outline-none"
        >
          <Dialog.Title className="text-xl font-bold text-gray-900 dark:text-gray-100 flex items-center gap-2 mb-4">
            <BookOpen className="h-5 w-5 text-blue-500" aria-hidden="true" />
            About Legal Oracle
          </Dialog.Title>

          <div className="space-y-6">
            {/* Overview Section */}
            <section>
              <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-gray-100">What is Legal Oracle?</h3>
              <p className="text-gray-600 dark:text-gray-400">
                Legal Oracle is an AI-powered legal research assistant that helps you navigate legislation.
                It provides accurate, up-to-date information by searching across hundreds of acts and regulations.
              </p>
            </section>

            {/* Official Resources */}
            <section>
              <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-gray-100">Official Resources</h3>
              {selectedJurisdictionAvailable ? (
                <div className="space-y-3">
                  <a
                    href={officialResourceLinks?.legislation}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                  >
                    <ExternalLink className="h-4 w-4" />
                    <span>{selectedJurisdictionName} Legislation</span>
                  </a>
                  <a
                    href={officialResourceLinks?.regulations}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                  >
                    <ExternalLink className="h-4 w-4" />
                    <span>{selectedJurisdictionName} Regulations</span>
                  </a>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                    Always verify information against official sources.
                  </p>
                </div>
              ) : (
                <p className="text-gray-600 dark:text-gray-400">
                  Official resources for {selectedJurisdictionName} will be available soon.
                </p>
              )}
            </section>

            {/* Technology Stack Section */}
            <section>
              <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-gray-100">Technology Stack</h3>
              <div className="space-y-4 text-gray-600 dark:text-gray-400">
                <p>Built using cutting-edge AI and search technology:</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* ChatGPT */}
                  <a
                    href="https://openai.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-red-100 dark:bg-red-900">
                      <MessageCircle className="h-5 w-5 text-red-600 dark:text-red-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        Open AI
                      </div>
                      <div className="text-sm text-gray-500">AI Assistant</div>
                    </div>
                  </a>
                  {/* Claude AI */}
                  <a
                    href="https://anthropic.com/claude"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 dark:bg-purple-900">
                      <Lightbulb className="h-5 w-5 text-purple-600 dark:text-purple-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        Claude AI
                      </div>
                      <div className="text-sm text-gray-500">Natural Language Processing</div>
                    </div>
                  </a>
                  {/* Voyage AI */}
                  <a
                    href="https://voyage.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900">
                      <Search className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        Voyage AI
                      </div>
                      <div className="text-sm text-gray-500">Text Embeddings & Reranking</div>
                    </div>
                  </a>
                  {/* Pinecone */}
                  <a
                    href="https://www.pinecone.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-green-100 dark:bg-green-900">
                      <Database className="h-5 w-5 text-green-600 dark:text-green-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        Pinecone
                      </div>
                      <div className="text-sm text-gray-500">Vector Database</div>
                    </div>
                  </a>
                  {/* React */}
                  <a
                    href="https://reactjs.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-cyan-100 dark:bg-cyan-900">
                      <Code2 className="h-5 w-5 text-cyan-600 dark:text-cyan-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        React
                      </div>
                      <div className="text-sm text-gray-500">Frontend Framework</div>
                    </div>
                  </a>
                  {/* Tailwind CSS */}
                  <a
                    href="https://tailwindcss.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-teal-100 dark:bg-teal-900">
                      <Paintbrush className="h-5 w-5 text-teal-600 dark:text-teal-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        Tailwind CSS
                      </div>
                      <div className="text-sm text-gray-500">Styling Framework</div>
                    </div>
                  </a>
                  {/* shadcn/ui */}
                  <a
                    href="https://ui.shadcn.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-indigo-100 dark:bg-indigo-900">
                      <Component className="h-5 w-5 text-indigo-600 dark:text-indigo-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        shadcn/ui
                      </div>
                      <div className="text-sm text-gray-500">UI Components</div>
                    </div>
                  </a>
                </div>
              </div>
            </section>

            {/* Data Freshness */}
            <section>
              <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-gray-100">Data Freshness</h3>
              {selectedJurisdictionAvailable ? (
                <>
                  <p className="text-gray-600 dark:text-gray-400">
                    Legislative database last updated: <span className="font-medium text-blue-600 dark:text-blue-400">November 24, 2024</span>
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                    We regularly update our database to ensure accuracy and completeness.
                  </p>
                </>
              ) : (
                <p className="text-gray-600 dark:text-gray-400">
                  Support for {selectedJurisdictionName} is now available.
                </p>
              )}
            </section>

            {/* Strengths */}
            <section>
              <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-gray-100">Strengths</h3>
              <ul className="space-y-2 text-gray-600 dark:text-gray-400">
                <li className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-0.5" />
                  <span>Fast, instant access to legislation</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-0.5" />
                  <span>Comprehensive coverage of acts and regulations</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-0.5" />
                  <span>Plain English explanations with legal citations</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-0.5" />
                  <span>Structured, consistent response format</span>
                </li>
              </ul>
            </section>

            {/* Limitations */}
            <section>
              <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-gray-100">Limitations</h3>
              <ul className="space-y-2 text-gray-600 dark:text-gray-400">
                <li className="flex items-start gap-2">
                  <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
                  <span>Not a substitute for professional legal advice</span>
                </li>
                <li className="flex items-start gap-2">
                  <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
                  <span>May not capture very recent legislative changes</span>
                </li>
                <li className="flex items-start gap-2">
                  <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
                  <span>Complex legal scenarios require human expertise</span>
                </li>
                <li className="flex items-start gap-2">
                  <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
                  <span>Currently focuses on {selectedJurisdictionName}; other jurisdictions coming soon</span>
                </li>
              </ul>
            </section>

            {/* Contact Section */}
            <section>
              <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-gray-100">Contact</h3>
              <div className="space-y-2">
                <a
                  href="mailto:contact@legaloracle.ai"
                  className="flex items-center gap-2 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  <Mail className="h-5 w-5" />
                  <span>contact@legaloracle.ai</span>
                </a>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  For questions, feedback, or support.
                </p>
              </div>
            </section>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Close
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

AboutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedJurisdictionName: PropTypes.string.isRequired,
  selectedJurisdictionAvailable: PropTypes.bool.isRequired,
  selectedJurisdictionCode: PropTypes.string.isRequired,
};


// StatsDisplay Component
const StatsDisplay = React.memo(({ selectedJurisdictions }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentJurisdictionIndex, setCurrentJurisdictionIndex] = useState(0);

  const jurisdictionStats = useMemo(() => {
    return selectedJurisdictions.map((jurisdictionCode) => {
      const jurisdiction = JURISDICTIONS.find(j => j.code === jurisdictionCode);
      if (jurisdiction?.available) {
        return {
          name: jurisdiction.name,
          stats: [
            { count: jurisdiction.acts, type: 'Acts' },
            { count: jurisdiction.regulations, type: 'Regulations' },
          ].filter(stat => stat.count > 0)
        };
      }
      return null;
    }).filter(Boolean);
  }, [selectedJurisdictions]);

  // Reset indexes if the length of jurisdictionStats changes
  useEffect(() => {
    setCurrentIndex(0);
    setCurrentJurisdictionIndex(0);
  }, [jurisdictionStats.length]);

  useEffect(() => {
    if (jurisdictionStats.length > 0) {
      const totalStats = jurisdictionStats.reduce((sum, j) => sum + j.stats.length, 0);

      if (totalStats > 0) {
        const interval = setInterval(() => {
          setCurrentIndex((prevIndex) => {
            // Defensive check that currentJurisdictionIndex is still valid
            if (currentJurisdictionIndex >= jurisdictionStats.length) {
              setCurrentJurisdictionIndex(0);
              return 0;
            }

            const currentJurStats = jurisdictionStats[currentJurisdictionIndex].stats;
            const nextIndex = prevIndex + 1;

            if (nextIndex >= currentJurStats.length) {
              // Move to next jurisdiction
              setCurrentJurisdictionIndex((prevJ) => (prevJ + 1) % jurisdictionStats.length);
              return 0;
            }
            return nextIndex;
          });
        }, 3000);

        return () => clearInterval(interval);
      }
    }
  }, [jurisdictionStats, currentJurisdictionIndex]);

  if (!jurisdictionStats.length) {
    return null;
  }

  const currentJurisdiction = jurisdictionStats[currentJurisdictionIndex] || {};
  const currentStat = currentJurisdiction.stats?.[currentIndex];
  if (!currentStat) {
    return null;
  }

  const allJurisdictionNames = jurisdictionStats.map((j) => j.name).join(' and ');

  return (
    <p className="text-sm text-gray-600 dark:text-gray-400">
      Searching across{' '}
      <span className="relative h-6 inline-flex align-baseline overflow-hidden">
        <AnimatePresence mode="wait">
          <motion.span
            key={`${currentJurisdictionIndex}-${currentIndex}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="inline-block whitespace-nowrap"
            aria-live="polite"
          >
            {currentStat.count.toLocaleString()} {currentStat.type}
          </motion.span>
        </AnimatePresence>
      </span>{' '}
      in {allJurisdictionNames}
    </p>
  );
});


StatsDisplay.propTypes = {
  selectedJurisdiction: PropTypes.string.isRequired,
};

const MermaidDiagram = ({ diagram }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [zoom, setZoom] = useState(1.5);
  const [isPanning, setIsPanning] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [lastPanPosition, setLastPanPosition] = useState({ x: 0, y: 0 });
  const MIN_ZOOM = 0.5;
  const MAX_ZOOM = 3;
  const ZOOM_STEP = 0.1;

  useEffect(() => {
    if (contentRef.current && diagram) {
      try {
        contentRef.current.innerHTML = '';
        const diagramId = `mermaid-diagram-${Date.now()}`;
        mermaid
          .render(diagramId, diagram)
          .then(({ svg }) => {
            if (contentRef.current) {
              contentRef.current.innerHTML = svg;

              // Reset position when diagram changes
              setPosition({ x: 0, y: 0 });
              setZoom(1);
            }
          })
          .catch((error) => {
            console.error('Failed to render mermaid diagram:', error);
            if (contentRef.current) {
              contentRef.current.innerHTML =
                '<p class="text-red-500">Failed to render diagram</p>';
            }
          });
      } catch (error) {
        console.error('Failed to render mermaid diagram:', error);
        if (contentRef.current) {
          contentRef.current.innerHTML =
            '<p class="text-red-500">Failed to render diagram</p>';
        }
      }
    }
  }, [diagram]);

  const handlePanStart = (e) => {
    e.preventDefault();
    setIsPanning(true);
    const clientX = e.type === 'touchstart' ? e.touches[0].clientX : e.clientX;
    const clientY = e.type === 'touchstart' ? e.touches[0].clientY : e.clientY;
    setLastPanPosition({
      x: clientX - position.x,
      y: clientY - position.y,
    });
  };

  const handlePanMove = (e) => {
    if (!isPanning) return;
    const clientX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
    const clientY = e.type === 'touchmove' ? e.touches[0].clientY : e.clientY;
    const newX = clientX - lastPanPosition.x;
    const newY = clientY - lastPanPosition.y;
    setPosition({ x: newX, y: newY });
  };

  const handlePanEnd = () => {
    setIsPanning(false);
  };

  const handleWheel = (e) => {
    e.preventDefault();
    const delta = e.deltaY > 0 ? -ZOOM_STEP : ZOOM_STEP;
    setZoom((prevZoom) => {
      const newZoom = Math.min(Math.max(prevZoom + delta, MIN_ZOOM), MAX_ZOOM);
      return newZoom;
    });
  };

  const handleZoomIn = () => {
    setZoom((prev) => Math.min(prev + ZOOM_STEP, MAX_ZOOM));
  };

  const handleZoomOut = () => {
    setZoom((prev) => Math.max(prev - ZOOM_STEP, MIN_ZOOM));
  };

  const handleZoomReset = () => {
    setZoom(1);
    setPosition({ x: 0, y: 0 });
  };

  // Add event listeners for touch devices
  useEffect(() => {
    const content = contentRef.current;
    if (!content) return;

    content.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      content.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div className="overflow-hidden">
      <div className="min-w-full">
        <div className="bg-gray-200 dark:bg-gray-800/50 rounded-lg border border-gray-300 dark:border-gray-700 p-4">
          {/* Controls */}
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-4">
            <div className="text-sm text-gray-600 dark:text-gray-400">
              This diagram shows the relationships between relevant legislation and regulations.
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={handleZoomOut}
                disabled={zoom <= MIN_ZOOM}
                className="p-2 rounded-lg bg-white dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors border border-gray-300 dark:border-gray-600 disabled:opacity-50"
                aria-label="Zoom Out"
              >
                <Minus className="w-4 h-4" />
              </button>
              <button
                onClick={handleZoomReset}
                className="px-3 py-2 rounded-lg bg-white dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors border border-gray-300 dark:border-gray-600"
              >
                <span className="text-sm font-medium">{Math.round(zoom * 100)}%</span>
              </button>
              <button
                onClick={handleZoomIn}
                disabled={zoom >= MAX_ZOOM}
                className="p-2 rounded-lg bg-white dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors border border-gray-300 dark:border-gray-600 disabled:opacity-50"
                aria-label="Zoom In"
              >
                <Plus className="w-4 h-4" />
              </button>
            </div>
          </div>

          {/* Diagram Viewport */}
          <div
            ref={containerRef}
            className="relative overflow-hidden w-full"
            style={{ height: '60vh' }}
          >
            {/* Draggable Content */}
            <div
              ref={contentRef}
              className="absolute inset-0 cursor-grab active:cursor-grabbing touch-pan-y touch-pan-x"
              style={{
                transform: `translate(${position.x}px, ${position.y}px) scale(${zoom})`,
                transformOrigin: 'center',
                transition: isPanning ? 'none' : 'transform 0.1s ease',
              }}
              onMouseDown={handlePanStart}
              onMouseMove={handlePanMove}
              onMouseUp={handlePanEnd}
              onMouseLeave={handlePanEnd}
              onTouchStart={handlePanStart}
              onTouchMove={handlePanMove}
              onTouchEnd={handlePanEnd}
            />
          </div>

          {/* Tip */}
          <div className="mt-4 text-xs text-gray-500 dark:text-gray-400 flex items-center gap-1">
            <Info className="w-4 h-4" />
            <span>
              Tip: Use pinch-to-zoom and drag gestures on touch devices, or scroll and drag with your mouse.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

MermaidDiagram.propTypes = {
  diagram: PropTypes.string.isRequired,
};


// ExampleQuestionsGrid Component
const ExampleQuestionsGrid = React.memo(({ onQuestionClick, selectedJurisdiction }) => {
  const [randomExamples, setRandomExamples] = useState([]);

  useEffect(() => {
    const getRandomQuestions = () => {
      const jurisdiction = JURISDICTIONS.find(j => j.code === selectedJurisdiction);
      const questions = jurisdiction?.questions || [];
      const shuffled = [...questions].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 5);
    };

    setRandomExamples(getRandomQuestions());

    const interval = setInterval(() => {
      setRandomExamples(getRandomQuestions());
    }, 10000);

    return () => clearInterval(interval);
  }, [selectedJurisdiction]);

  return (
    <div className="space-y-8" aria-label="Example Questions">
      <div className="text-center space-y-4">
        <p className="text-sm text-gray-600 dark:text-gray-400">Popular questions:</p>
        <div className="flex flex-wrap gap-2 justify-center">
          {randomExamples.map((example, index) => (
            <button
              key={index}
              onClick={() => onQuestionClick(example)}
              className="px-4 py-2 bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full text-sm text-gray-700 dark:text-gray-300 transition-colors border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label={`Search for: ${example}`}
            >
              {example}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
});


ExampleQuestionsGrid.propTypes = {
  onQuestionClick: PropTypes.func.isRequired,
};

// Footer Component
const Footer = React.memo(({ setShowDisclaimer, setShowAbout }) => (
  <footer className="mt-12 pt-6 border-t border-gray-300 dark:border-gray-800">
    <div className="flex flex-col items-center justify-center gap-4 text-sm text-gray-600 dark:text-gray-400">
      <div className="flex items-center gap-2">
        <span>Created by Hamish Cameron</span>
      </div>
      <div className="flex items-center gap-4">
        <a
          href="https://www.linkedin.com/in/hamishcameron/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-1 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
          title="Connect on LinkedIn"
          aria-label="Connect on LinkedIn"
        >
          <FaLinkedin className="w-6 h-6" aria-hidden="true" />
        </a>
        <a
          href="mailto:contact@legaloracle.ai"
          className="flex items-center gap-1 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
          title="Email Us"
          aria-label="Email Us"
        >
          <Mail className="w-6 h-6" aria-hidden="true" />
        </a>
      </div>
      <div className="flex items-center gap-4">
        <button
          onClick={() => setShowAbout(true)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label="About Legal Oracle"
        >
          <Info className="h-5 w-5" aria-hidden="true" />
          About Legal Oracle
        </button>
        <button
          onClick={() => setShowDisclaimer(true)}
          className="flex items-center gap-2 px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors focus:outline-none focus:ring-2 focus:ring-yellow-500"
          aria-label="View Disclaimer"
        >
          <AlertCircle className="h-5 w-5" aria-hidden="true" />
          View Disclaimer
        </button>
      </div>
    </div>
  </footer>
));

Footer.propTypes = {
  setShowDisclaimer: PropTypes.func.isRequired,
  setShowAbout: PropTypes.func.isRequired,
};


// (Continue with the rest of the components: SectionPlaceholder, FormattedAnswer, and the main App component)

// SectionPlaceholder Component
const SectionPlaceholder = React.memo(
  ({
    title,
    icon: Icon,
    isLoading,
    content,
    isCollapsed,
    onToggleCollapse,
    renderCustomContent,
  }) => (
    <motion.div
      className={`relative rounded-lg overflow-hidden border-2 transition-all duration-300 ${
        isLoading ? 'border-blue-500' : 'border-gray-300 dark:border-gray-600'
      }`}
      initial={{ scale: 0.95, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.95, opacity: 0 }}
      whileHover={{
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
        borderColor: isLoading ? '#60a5fa' : '#3b82f6',
      }}
      transition={{ duration: 0.5 }}
    >
      <div
        className={`p-4 flex items-center gap-3 border-b border-gray-300 dark:border-gray-700 ${
          isLoading ? 'animate-pulse' : ''
        }`}
      >
        <Icon className="h-5 w-5 text-blue-500 dark:text-blue-400" aria-hidden="true" />
        <h2 className="font-bold text-gray-800 dark:text-gray-100 flex-grow">{title}</h2>
        {isLoading && (
          <Loader2
            className="h-4 w-4 text-gray-700 dark:text-gray-400 animate-spin mr-2"
            aria-label="Loading"
          />
        )}
        {!isLoading && (
          <button
            onClick={onToggleCollapse}
            className="text-gray-700 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-label={isCollapsed ? `Expand ${title}` : `Collapse ${title}`}
          >
            {isCollapsed ? (
              <ChevronDown className="h-5 w-5" aria-hidden="true" />
            ) : (
              <ChevronUp className="h-5 w-5" aria-hidden="true" />
            )}
          </button>
        )}
      </div>
      <AnimatePresence initial={false}>
        {!isCollapsed && (
          <motion.div
            key="content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="overflow-hidden"
          >
            <div className="p-4">
              {isLoading ? (
                <p className="text-gray-700 dark:text-gray-400 italic">
                  Loading {title.toLowerCase()}...
                </p>
              ) : renderCustomContent ? (
                renderCustomContent()
              ) : (
                <div className="space-y-2">
                  {content?.map((line, lineIndex) => {
                    if (!line?.trim()) return null;
                    const trimmedLine = line.trim();
                    const isNumberedPoint = /^\d+\./.test(trimmedLine);
                    const isBulletPoint = /^-\s*/.test(trimmedLine);

                    return (
                      <div
                        key={lineIndex}
                        className={`
                          text-gray-800 dark:text-gray-200
                          ${isNumberedPoint ? 'flex gap-2 items-start' : ''}
                          ${isBulletPoint ? 'ml-4 flex gap-2 items-start' : ''}
                        `}
                      >
                        {isNumberedPoint && (
                          <span className="text-blue-500 dark:text-blue-400 font-medium">
                            {trimmedLine.match(/^\d+\./)[0]}
                          </span>
                        )}
                        {isBulletPoint && (
                          <span
                            className="text-blue-500 dark:text-blue-400 mt-1.5"
                            aria-hidden="true"
                          >
                            •
                          </span>
                        )}
                        <span>
                          {isNumberedPoint
                            ? trimmedLine.replace(/^\d+\.\s*/, '').trim()
                            : isBulletPoint
                            ? trimmedLine.replace(/^-\s*/, '').trim()
                            : trimmedLine}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
);

SectionPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  isLoading: PropTypes.bool.isRequired,
  content: PropTypes.arrayOf(PropTypes.string),
  isCollapsed: PropTypes.bool.isRequired,
  onToggleCollapse: PropTypes.func.isRequired,
  renderCustomContent: PropTypes.func,
};

const RelationshipsSection = React.memo(({ diagram }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && diagram) {
      try {
        containerRef.current.innerHTML = '';
        const diagramId = `mermaid-diagram-${Date.now()}`;
        mermaid.render(diagramId, diagram)
          .then(({ svg }) => {
            if (containerRef.current) {
              containerRef.current.innerHTML = svg;
            }
          })
          .catch((error) => {
            console.error('Failed to render mermaid diagram:', error);
            if (containerRef.current) {
              containerRef.current.innerHTML = '<p class="text-red-500">Failed to render diagram</p>';
            }
          });
      } catch (error) {
        console.error('Failed to render mermaid diagram:', error);
        if (containerRef.current) {
          containerRef.current.innerHTML = '<p class="text-red-500">Failed to render diagram</p>';
        }
      }
    }
  }, [diagram]);

  return (
    <div className="overflow-x-auto">
      <div className="min-w-full">
        <div className="bg-gray-200 dark:bg-gray-800/50 rounded-lg border border-gray-300 dark:border-gray-700 p-4">
          <div className="text-sm text-gray-600 dark:text-gray-400 mb-4">
            This diagram shows the relationships between relevant legislation and regulations. 
            Solid lines indicate direct references, while dotted lines show reverse references.
          </div>
          <div 
            ref={containerRef}
            className="mermaid-diagram overflow-x-auto w-full"
          />
        </div>
      </div>
    </div>
  );
});

RelationshipsSection.propTypes = {
  diagram: PropTypes.string.isRequired,
};

// Main FormattedAnswer Component
const FormattedAnswer = React.memo(
  ({ answer = '', loadingSections, result, sectionsConfig, collapsedSections, onToggleCollapse, wsRef, selectedScreenshot, setSelectedScreenshot }) => {

    const normalizedAnswer = (answer || '').replace(/\r\n/g, '\n').replace(/\r/g, '\n');
    const sectionHeaderRegex = /^\d+\.\s*(DIRECT ANSWER|LEGAL BASIS|KEY REQUIREMENTS|LIMITATIONS AND EXCEPTIONS):$/gm;
    const matches = [...normalizedAnswer.matchAll(sectionHeaderRegex)];

	// Inside FormattedAnswer component:
	const processContent = (content) => {
	  const pageWithScreenshotRegex = /Page\s+(\d+)\]?\s*\[([^\]]+\.(?:jpg|jpeg|png|gif))\]/gi;
	  
	  const getQuoteFromLine = (line) => {
		// Case 1: Legal Basis - text in quotes
		const quotedText = line.match(/"([^"]+)"/) || [];
		if (quotedText[1]) {
		  return quotedText[1];
		}

		// Case 2: Requirements and Limitations
		const requirementMatch = line.match(/^[•\d\.]\s*(.*?)\s+(?=[A-Za-z\s]+Act|[A-Za-z\s]+Regulations)/);
		if (requirementMatch?.[1]) {
		  return requirementMatch[1];
		}

		// Case 3: Fallback - square brackets not containing file extensions
		const matches = line.match(/\[(.*?)\]/g) || [];
		const nonImageMatches = matches.filter(match => 
		  !match.toLowerCase().match(/\.(jpg|jpeg|png|gif)\]$/i)
		);
		if (nonImageMatches.length > 0) {
		  return nonImageMatches[0].replace(/^\[|\]$/g, '');
		}

		return '';
	  };

	  const getTitleFromLine = (line) => {
		const match = line.match(/([A-Za-z\s]+(?:Act|Regulations|Rules)(?:\s+\d+)?),\s*Section/);
		return match ? match[1].trim() : 'Document';
	  };

	  return content.split('\n').map((line, index) => {
		if (!line || typeof line !== 'string') {
		  return null;
		}

		const parts = [];
		let lastIndex = 0;
		let match;

		const quote = getQuoteFromLine(line);
		const title = getTitleFromLine(line);

		while ((match = pageWithScreenshotRegex.exec(line)) !== null) {
		  const [fullMatch, pageNum, filename] = match;
		  const matchStart = match.index;
		  const matchEnd = pageWithScreenshotRegex.lastIndex;

		  parts.push(line.slice(lastIndex, matchStart));

		  parts.push(
			<button
			  key={`${index}-${pageNum}-${filename}`}
			  onClick={() => {
				setSelectedScreenshot({
				  imageUrl: filename,
				  title: title,
				  pageNumber: pageNum,
				  quote: quote
				});

				if (quote && quote !== filename && wsRef.current?.readyState === WebSocket.OPEN) {
				  wsRef.current.send(JSON.stringify({
					action: 'locate_quote',
					body: {
					  screenshot_url: filename,
					  quote: quote
					}
				  }));
				}
			  }}
			  className="text-blue-600 dark:text-blue-400 hover:underline focus:outline-none"
			>
			  Page {pageNum}
			</button>
		  );

		  lastIndex = matchEnd;
		}

		parts.push(line.slice(lastIndex));
		return <div key={index}>{parts}</div>;
	  });
	};

    const formattedSections = [];
    for (let i = 0; i < matches.length; i++) {
      const match = matches[i];
      const sectionTitle = match[1];
      const startIndex = match.index + match[0].length;
      const endIndex = i < matches.length - 1 ? matches[i + 1].index : normalizedAnswer.length;
      const sectionContent = normalizedAnswer.substring(startIndex, endIndex).trim();
      const contentLines = sectionContent.split('\n').filter((line) => line.trim() !== '');

      formattedSections.push({
        title: sectionTitle,
        content: contentLines,
        icon: sectionsConfig[sectionTitle]?.icon,
      });
    }

    const allSections = [
      'DIRECT ANSWER',
      'LEGAL BASIS',
      'KEY REQUIREMENTS',
      'LIMITATIONS AND EXCEPTIONS',
      'SOURCES',
    ];

    return (
      <div className="space-y-6">
        {selectedScreenshot && (
          <ImageModal
            isOpen={!!selectedScreenshot}
            onClose={() => setSelectedScreenshot(null)}
            imageUrl={selectedScreenshot.imageUrl}
            title={selectedScreenshot.title}
            pageNumber={selectedScreenshot.pageNumber}
			quote={selectedScreenshot.quote}
			highlight={selectedScreenshot.highlight} // Add this line
          />
        )}

        {allSections.map((sectionTitle) => {
          const section = formattedSections.find((s) => s.title === sectionTitle);
          const isLoading = loadingSections.includes(sectionTitle) && !section;
          const isCollapsed = collapsedSections[sectionTitle];

          if (sectionTitle === 'SOURCES' && result?.sources) {
            return (
              <div key={sectionTitle} className="space-y-6">
                <SectionPlaceholder
                  title={sectionTitle}
                  icon={sectionsConfig[sectionTitle]?.icon}
                  isLoading={isLoading}
                  isCollapsed={isCollapsed}
                  onToggleCollapse={() => onToggleCollapse(sectionTitle)}
                  renderCustomContent={() => (
                    <div className="space-y-3">
                      {result.sources.map((source, index) => (
                        <div
                          key={index}
                          className="bg-gray-200 dark:bg-gray-800/50 rounded-lg border border-gray-300 dark:border-gray-700 p-3"
                        >
                          <div className="flex items-start justify-between gap-4">
                            <div className="flex-grow">
                              <h4 className="font-medium text-gray-800 dark:text-gray-200">
                                {source.title}
                              </h4>
                              {source.pages?.length > 0 && (
                                <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                  Pages cited: {source.pages.join(', ')}
                                </p>
                              )}
                            </div>
                            {source.url && (
                              <a
                                href={source.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center gap-2 px-3 py-2 text-sm bg-blue-600/20 text-blue-600 dark:text-blue-400 rounded-md hover:bg-blue-600/30 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                                aria-label={`View ${source.title} online`}
                              >
                                <ExternalLink className="h-4 w-4" aria-hidden="true" />
                                View Online
                              </a>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />

                {result?.mermaid_diagram && (
                  <SectionPlaceholder
                    title="Legislative Relationships"
                    icon={Share2}
                    isLoading={false}
                    isCollapsed={collapsedSections['RELATIONSHIPS']}
                    onToggleCollapse={() => onToggleCollapse('RELATIONSHIPS')}
                    renderCustomContent={() => (
                      <MermaidDiagram diagram={result.mermaid_diagram} />
                    )}
                  />
                )}
              </div>
            );
          }

          return (
            <SectionPlaceholder
              key={sectionTitle}
              title={sectionTitle}
              icon={sectionsConfig[sectionTitle]?.icon}
              isLoading={isLoading}
              isCollapsed={isCollapsed}
              onToggleCollapse={() => onToggleCollapse(sectionTitle)}
              renderCustomContent={
                section
                  ? () => (
                      <div className="space-y-2">
                        {processContent(section.content.join('\n'))}
                      </div>
                    )
                  : undefined
              }
            />
          );
        })}
      </div>
    );
  }
);

FormattedAnswer.propTypes = {
  answer: PropTypes.string,
  loadingSections: PropTypes.arrayOf(PropTypes.string).isRequired,
  result: PropTypes.object,
  sectionsConfig: PropTypes.object.isRequired,
  collapsedSections: PropTypes.object.isRequired,
  onToggleCollapse: PropTypes.func.isRequired,
  wsRef: PropTypes.object.isRequired,  
  selectedScreenshot: PropTypes.object,
  setSelectedScreenshot: PropTypes.func.isRequired
};

// Main App Component
export default function App() {
  // Theme and Font Size State
  const [theme, setTheme] = useState(() => localStorage.getItem('theme') || 'light');
const [fontSize, setFontSize] = useState(() => localStorage.getItem('fontSize') || 'normal');


  // Other States
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [question, setQuestion] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [actionMessage, setActionMessage] = useState('');
  const [connected, setConnected] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [loadingSections, setLoadingSections] = useState([]);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [collapsedSections, setCollapsedSections] = useState({
    'DIRECT ANSWER': false,
    'LEGAL BASIS': false,
    'KEY REQUIREMENTS': false,
    'LIMITATIONS AND EXCEPTIONS': false,
    'SOURCES': false,
    'RELATIONSHIPS': false,
  });
  const [selectedJurisdiction, setSelectedJurisdiction] = useState('SA');
  const [selectedScreenshot, setSelectedScreenshot] = useState(null);

  const wsRef = useRef(null);
  const manualCloseRef = useRef(false);

  const MAX_RECONNECT_ATTEMPTS = 5;
  const [reconnectAttempts, setReconnectAttempts] = useState(0);

  // Sections Configuration
  const sectionsConfig = useMemo(
    () => ({
      'DIRECT ANSWER': { icon: Lightbulb },
      'LEGAL BASIS': { icon: BookOpen },
      'KEY REQUIREMENTS': { icon: CheckCircle },
      'LIMITATIONS AND EXCEPTIONS': { icon: AlertTriangle },
      SOURCES: { icon: Link },
    }),
    []
  );
  
	const [compareMode, setCompareMode] = useState(false);
	const [selectedJurisdictions, setSelectedJurisdictions] = useState(['SA']); // Now an array

  
	const JURISDICTIONS = [
	  {
		name: 'South Australia',
		code: 'SA',
		available: true,
		acts: 569,        // Updated from 570 to 569
		regulations: 469, // Updated from 468 to 469
	  },
	  {
		name: 'Victoria', 
		code: 'VIC',
		available: true,  
		acts: 777,        // Updated to 777
		regulations: 408, // Updated to 408
	  },
	  {
		name: 'Commonwealth (Federal)',
		code: 'CWTH',
		available: true,
		acts: 4636,        
		regulations: 0,    
	  }	  
	];

const selectedJurisdictionName = useMemo(() => {
  const primaryCode = selectedJurisdictions[0];
  return JURISDICTIONS.find((j) => j.code === primaryCode)?.name || '';
}, [selectedJurisdictions]);

const selectedJurisdictionAvailable = useMemo(() => {
  const primaryCode = selectedJurisdictions[0];
  return JURISDICTIONS.find((j) => j.code === primaryCode)?.available;
}, [selectedJurisdictions]);

  // Initialize Theme and Font Size
	useEffect(() => {
	  const savedTheme = localStorage.getItem('theme') || 'light';
	  const savedFontSize = localStorage.getItem('fontSize') || 'normal';
	  setTheme(savedTheme);
	  setFontSize(savedFontSize);
	  
	  // Set the CSS variable for the font size
	  document.documentElement.style.setProperty(
		'--base-font-size',
		savedFontSize === 'large' ? '1.125rem' : '1rem'
	  );
	}, []);
	
useEffect(() => {
  if (!compareMode && selectedJurisdictions.length > 1) {
    // If leaving compare mode with multiple selections, keep only the first one
    setSelectedJurisdictions([selectedJurisdictions[0]]);
  }
}, [compareMode]);	

  // Apply Theme
  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.toggle('dark', theme === 'dark');
    localStorage.setItem('theme', theme);
  }, [theme]);

  // Save Font Size
  useEffect(() => {
    localStorage.setItem('fontSize', fontSize);
  }, [fontSize]);

  // WebSocket Connection
  useEffect(() => {
    connectWebSocket();
    return () => {
      manualCloseRef.current = true;
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.close();
      }
    };
  }, []);

  const connectWebSocket = () => {
    const websocket = new WebSocket('wss://2lv389ryo5.execute-api.ap-southeast-2.amazonaws.com/production');

    websocket.onopen = () => {
      console.log('WebSocket Connected');
      setConnected(true);
      setReconnectAttempts(0);
    };

    websocket.onclose = (event) => {
      console.log('WebSocket Closed:', event);
      setConnected(false);

      if (!manualCloseRef.current && reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
        const timeout = Math.pow(2, reconnectAttempts) * 1000; // Exponential backoff
        setTimeout(() => {
          setReconnectAttempts((prev) => prev + 1);
          connectWebSocket();
        }, timeout);
      } else if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
        setError('Unable to reconnect. Please refresh the page or try again later.');
      }
    };

    websocket.onerror = (error) => {
      console.error('WebSocket Error:', error);
      setError('Connection error. Please try again later.');
      websocket.close();
    };

// In App.js, modify the websocket.onmessage handler:

	websocket.onmessage = (event) => {
	  try {
		if (!event.data) {
		  console.warn('Received empty message');
		  return;
		}

		const data = JSON.parse(event.data);
	
		if (data.type === 'quote_location') {
		  setSelectedScreenshot(prev => ({
			...prev,
			highlight: data.data.coordinates,
			confidence: data.data.confidence,
			found_exact: data.data.found_exact
		  }));
		}	

		if (data.error) {
		  setError(data.error);
		  setLoading(false);
		} else if (data.chunk !== undefined) {
		  setResult((prev) => ({
			...(prev || {}),
			question,
			answer: ((prev?.answer) || '') + data.chunk,
		  }));

		  // Detect section changes
		  const sections = {
			'DIRECT ANSWER': '1. DIRECT ANSWER:',
			'LEGAL BASIS': '2. LEGAL BASIS:',
			'KEY REQUIREMENTS': '3. KEY REQUIREMENTS:',
			'LIMITATIONS AND EXCEPTIONS': '4. LIMITATIONS AND EXCEPTIONS:',
		  };

		  Object.entries(sections).forEach(([section, header]) => {
			if (data.chunk.includes(header)) {
			  setCurrentSection(section);
			  setLoadingSections((prevSections) => {
				const updatedSections = [...prevSections];
				if (!updatedSections.includes(section)) {
				  updatedSections.push(section);
				}
				return updatedSections;
			  });
			}
		  });
		} else if (data.sources) {
		  // Update result with sources
		  setResult((prev) => ({
			...(prev || {}),
			sources: data.sources,
		  }));
		} else if (data.mermaid_diagram) {
		  // Update result with mermaid diagram
		  setResult((prev) => ({
			...(prev || {}),
			mermaid_diagram: data.mermaid_diagram,
		  }));
		}

		// Handle completion
		if (data.done) {
		  if (!data.chunk && !data.sources && !data.mermaid_diagram) {
			// This is the final completion message
			setLoading(false);
			setCurrentSection(null);
			setLoadingSections([]);
		  }
		}

	  } catch (err) {
		console.error('Failed to parse message:', event.data);
		console.error('Parse error:', err);
		setError('Error processing server response');
		setLoading(false);
	  }
	};

    wsRef.current = websocket;
  };

  // Load History
  useEffect(() => {
    try {
      const savedHistory = localStorage.getItem('legalOracleHistory');
      if (savedHistory) {
        const parsedHistory = JSON.parse(savedHistory);
        if (Array.isArray(parsedHistory)) {
          const validHistory = parsedHistory.filter(
            (item) =>
              typeof item.question === 'string' &&
              typeof item.answer === 'string' &&
              typeof item.timestamp === 'string'
          );
          setHistory(validHistory);
        } else {
          console.warn('History format invalid. Initializing empty.');
          setHistory([]);
        }
      } else {
        setHistory([]);
      }
    } catch (err) {
      console.error('Failed to load history:', err);
      setHistory([]);
    }
  }, []);

  // Save History
  useEffect(() => {
    try {
      localStorage.setItem('legalOracleHistory', JSON.stringify(history));
    } catch (err) {
      console.error('Failed to save history:', err);
    }
  }, [history]);

  // Action Message Timeout
  useEffect(() => {
    if (actionMessage) {
      const timer = setTimeout(() => setActionMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [actionMessage]);

  // Show Disclaimer
  useEffect(() => {
    const lastAccepted = localStorage.getItem('legalOracle_accepted_time');
    const now = new Date().getTime();

    if (!lastAccepted || now - parseInt(lastAccepted, 10) > 24 * 60 * 60 * 1000) {
      setShowDisclaimer(true);
    }
  }, []);

  // Progress Messages
  useEffect(() => {
    if (loading) {
      const progressMessages = [
        'Analysing your question...',
        'Fetching relevant documents...',
        'Processing information...',
        'Generating answer...',
      ];
      let index = 0;
      setProgressMessage(progressMessages[index]);

      const interval = setInterval(() => {
        index = (index + 1) % progressMessages.length;
        setProgressMessage(progressMessages[index]);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [loading]);

  // Scroll to Top Visibility
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Update Suggestions
  useEffect(() => {
    if (question.trim().length > 0) {
      const filteredSuggestions = history
        .map((item) => item.question)
        .filter((q) => q.toLowerCase().includes(question.toLowerCase()))
        .slice(0, MAX_SUGGESTIONS);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [question, history]);

  // Handle Scroll to Top
  const handleScrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // Handle Disclaimer Accept
  const handleDisclaimerAccept = useCallback(() => {
    localStorage.setItem('legalOracle_accepted_time', new Date().getTime().toString());
    setShowDisclaimer(false);
  }, []);

  // Handle Form Submit
	const handleSubmit = async (e) => {
	  e.preventDefault();
	  if (!question.trim() || !connected) return;
		
	  if (compareMode && selectedJurisdictions.length < 2) {
		setError('Please select at least 2 jurisdictions to compare');
		return;
	  }

    setLoading(true);
    setError(null);
    setShowHistory(false);
    setResult(null);
    setCurrentSection(null);
    setLoadingSections([]);
    setProgressMessage('Analyzing your question...');

    try {
      wsRef.current.send(
        JSON.stringify({
          action: 'query',
          body: {
            question: sanitizeInput(question),
            jurisdiction: selectedJurisdictions.join(',')
          },
        })
      );
    } catch (error) {
      console.error('Send error:', error);
      setError('Failed to send question. Please try again.');
      setLoading(false);
    }
  };

  // Handle Example Click
  const handleExampleClick = useCallback((exampleQuestion) => {
    setQuestion(exampleQuestion);
    setShowHistory(false);
    setError(null);
    setSuggestions([]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // Handle History Item Click
  const handleHistoryItemClick = useCallback(
    (historyItem) => {
      setResult(historyItem);
      setQuestion('');
      setShowHistory(false);
      setLoading(false);
      setCurrentSection(null);
      setLoadingSections([]);
      setSuggestions([]);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [setResult]
  );

  // Clear History
  const clearHistory = useCallback(() => {
    try {
      setHistory([]);
      localStorage.removeItem('legalOracleHistory');
      setShowHistory(false);
      setActionMessage('History cleared');
    } catch (err) {
      console.error('Failed to clear history:', err);
      setError('Failed to clear history');
    }
  }, []);

  // Handle New Search
  const handleNewSearch = useCallback(() => {
    setResult(null);
    setQuestion('');
    setError(null);
    setCurrentSection(null);
    setLoadingSections([]);
    setSuggestions([]);
  }, []);

  // Handle Copy
  const handleCopy = useCallback(async () => {
    if (!result) return;

    try {
      await navigator.clipboard.writeText(result.answer);
      setActionMessage('Copied to clipboard');
    } catch (err) {
      console.error('Copy failed:', err);
      setActionMessage('Failed to copy');
    }
  }, [result]);

  // Handle Share
  const handleShare = useCallback(async () => {
    if (!result) return;

    const text = `Q: ${result.question}\nA: ${result.answer}`;

    try {
      if (navigator.share) {
        await navigator.share({ text });
        setActionMessage('Shared successfully');
      } else {
        await navigator.clipboard.writeText(text);
        setActionMessage('Copied to clipboard');
      }
    } catch (err) {
      console.error('Share failed:', err);
      setActionMessage('Failed to share');
    }
  }, [result]);

  // Toggle Theme
  const toggleTheme = useCallback(() => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
  }, [theme]);

  // Toggle Font Size
const toggleFontSize = useCallback(() => {
  const newSize = fontSize === 'normal' ? 'large' : 'normal';
  setFontSize(newSize);
  localStorage.setItem('fontSize', newSize);
  
  // Update the CSS variable
  document.documentElement.style.setProperty(
    '--base-font-size',
    newSize === 'large' ? '1.125rem' : '1rem'
  );
}, [fontSize]);

  // Handle Suggestion Click
  const handleSuggestionClick = useCallback((suggestion) => {
    setQuestion(suggestion);
    setSuggestions([]);
  }, []);

  // Toggle Collapse
  const toggleCollapse = useCallback((sectionTitle) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [sectionTitle]: !prev[sectionTitle],
    }));
  }, []);
  
// SEODescriptionBoxes Component
// SEODescriptionBoxes Component
const SEODescriptionBoxes = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* First Box */}
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm border border-gray-200 dark:border-gray-700">
        <h2 className="text-xl font-semibold mb-4 flex items-center gap-2">
          <BookOpen className="h-5 w-5 text-blue-500" aria-hidden="true" />
          About Legal Oracle
        </h2>
        <p className="text-gray-600 dark:text-gray-400 leading-relaxed">
          Legal Oracle is your AI-powered legal research assistant, helping you navigate complex legal landscapes by providing accurate, up-to-date information from official legislation and regulations. Perfect for citizens, business owners, and legal professionals seeking quick, reliable legal information.
        </p>
      </div>

      {/* Second Box */}
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm border border-gray-200 dark:border-gray-700">
        <h2 className="text-xl font-semibold mb-4">Why Use Legal Oracle?</h2>
        <ul className="space-y-3 text-gray-600 dark:text-gray-400">
          <li className="flex items-center gap-2">
            <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
            Comprehensive coverage of Acts and Regulations
          </li>
          <li className="flex items-center gap-2">
            <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
            Updated with the latest legislative changes
          </li>
          <li className="flex items-center gap-2">
            <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
            Plain English explanations of complex laws
          </li>
          <li className="flex items-center gap-2">
            <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
            Citations to relevant legislation
          </li>
          <li className="flex items-center gap-2">
            <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
            Support for multiple jurisdictions (Victoria and Cth now available!)
          </li>
        </ul>
      </div>
    </div>
  );
};




  
const JurisdictionCardSelect = ({
  compareMode,
  selectedJurisdictions,
  onChange,
}) => {
  const [activeTooltip, setActiveTooltip] = useState(null);

  const getTooltipContent = (type) => {
    if (type === 'Acts') {
      return 'Acts are laws passed by Parliament';
    } else if (type === 'Regs') {
      return 'Regulations are detailed rules under Acts';
    }
    return '';
  };

  const Badge = ({ count, type, index }) => (
    <div className="relative">
      <button
        className={`text-xs ${
          type === 'Acts'
            ? 'bg-blue-100 dark:bg-blue-900/50 text-blue-800 dark:text-blue-200'
            : 'bg-green-100 dark:bg-green-900/50 text-green-800 dark:text-green-200'
        } px-2 py-0.5 rounded-full hover:ring-2 hover:ring-blue-300 dark:hover:ring-blue-700 transition-all`}
        onClick={(e) => {
          e.stopPropagation();
          const tooltipId = `${type}-${count}`;
          setActiveTooltip((prev) => (prev === tooltipId ? null : tooltipId));
        }}
        onMouseEnter={() => setActiveTooltip(`${type}-${count}`)}
        onMouseLeave={() => setActiveTooltip(null)}
      >
        {count.toLocaleString()} {type}
      </button>

      {activeTooltip === `${type}-${count}` && (
        <div
          className={`absolute bottom-full mb-2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg shadow-lg whitespace-nowrap z-50 ${
            index === 0 ? 'left-0' : 'left-1/2 transform -translate-x-1/2'
          }`}
        >
          {getTooltipContent(type)}
          <div
            className={`absolute -bottom-1 ${
              index === 0 ? 'left-4' : 'left-1/2 transform -translate-x-1/2'
            } w-2 h-2 bg-gray-900 rotate-45`}
          />
        </div>
      )}
    </div>
  );

const handleCardClick = (code, available) => {
  if (!available) return;

  if (!compareMode) {
    // Single select mode - always allow selection of any available jurisdiction
    onChange([code]);
  } else {
    // Multi select mode
    if (selectedJurisdictions.includes(code)) {
      // Remove if already selected
      onChange(selectedJurisdictions.filter(j => j !== code));
    } else {
      // Add to selection
      onChange([...selectedJurisdictions, code]);
    }
  }
};


  return (
    <div className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden bg-white dark:bg-gray-900">
<div className="px-3 py-2 border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 flex justify-between items-center">
  <h2 className="text-sm font-medium text-gray-700 dark:text-gray-300">
    {compareMode ? "Select" : "Select"}
  </h2>
  
  <div className="flex items-center gap-2">
    <div className="flex items-center gap-1">
      <span className="text-sm text-gray-600 dark:text-gray-400">Compare</span>
      <span className="text-yellow-500 font-medium text-sm">(Experimental!)</span>
    </div>
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div className="flex items-center gap-2">
            {compareMode && (
              <AlertCircle className="h-4 w-4 text-yellow-500" />
            )}
            <div className="relative inline-block w-12 h-6 align-middle select-none">
              <input
                type="checkbox"
                id="compare-toggle"
                role="switch"
                aria-checked={compareMode}
                checked={compareMode}
                onChange={(e) => setCompareMode(e.target.checked)}
                className={`
                  absolute block w-6 h-6 bg-white border-2 border-gray-300 rounded-full appearance-none cursor-pointer
                  focus:outline-none focus:ring-2 focus:ring-blue-500 
                  peer transition-all
                `}
                style={{
                  left: 0,
                  top: 0,
                  transform: compareMode ? 'translateX(100%)' : 'translateX(0)',
                }}
              />
              <label
                htmlFor="compare-toggle"
                className={`
                  block h-6 rounded-full bg-gray-300 dark:bg-gray-600
                  cursor-pointer peer-checked:bg-blue-600
                `}
              />
            </div>
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="top"
            align="end"
            className="bg-gray-800 text-white text-sm px-3 py-2 rounded-lg shadow-lg max-w-xs"
          >
            <p className="font-medium mb-1">Compare Jurisdictions</p>
            <p className="text-gray-200 text-xs">
              Enable to compare legislation across multiple jurisdictions
            </p>
            <Tooltip.Arrow className="fill-gray-800" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  </div>
</div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 p-2">
        {JURISDICTIONS.map((jurisdiction, index) => {
          const isDisabled = !jurisdiction.available;
          const isSelected = selectedJurisdictions.includes(jurisdiction.code);

          return (
            <button
              key={jurisdiction.code}
              onClick={() => handleCardClick(jurisdiction.code, !isDisabled)}
              disabled={isDisabled}
              className={`relative p-2 rounded-lg border transition-all duration-200
                ${
                  isSelected
                    ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
                    : isDisabled
                    ? 'border-yellow-500 bg-yellow-50 dark:bg-yellow-900/20 cursor-not-allowed'
                    : 'border-gray-200 dark:border-gray-700 hover:border-blue-300 dark:hover:border-blue-700'
                }
              `}
            >
              <div className="flex flex-col gap-1">
                <div className="flex items-start justify-between">
                  <h3
                    className={`text-sm font-medium ${
                      isSelected
                        ? 'text-blue-700 dark:text-blue-400'
                        : 'text-gray-700 dark:text-gray-200'
                    }`}
                  >
                    {jurisdiction.name}
                  </h3>
                  {isSelected && (
                    <CheckCircle className="h-4 w-4 text-blue-500 ml-1 flex-shrink-0" />
                  )}
                </div>

                {jurisdiction.available && (
                  <div className="flex flex-wrap items-center gap-1">
                    {jurisdiction.acts > 0 && (
                      <Badge count={jurisdiction.acts} type="Acts" index={index} />
                    )}
                    {jurisdiction.regulations > 0 && (
                      <Badge count={jurisdiction.regulations} type="Regs" index={index} />
                    )}
                  </div>
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

JurisdictionCardSelect.propTypes = {
  compareMode: PropTypes.bool.isRequired,
  selectedJurisdictions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

  // Continue with the return statement
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <FontSizeContext.Provider value={{ fontSize, toggleFontSize }}>
        <div>
          <div
            className={`min-h-screen bg-gradient-to-b from-white to-gray-100 dark:from-gray-900 dark:to-gray-800 text-gray-900 dark:text-gray-100 p-4 md:p-6 transition-colors duration-500 ${fontSize}`}
          >
            <a href="#main-content" className="sr-only focus:not-sr-only">
              Skip to main content
            </a>

            <ErrorBoundary>
              <Suspense fallback={<div>Loading...</div>}>
                {showDisclaimer && (
                  <DisclaimerModal isOpen={showDisclaimer} onClose={handleDisclaimerAccept} />
                )}
                {showAbout && (
                  <AboutModal
                    isOpen={showAbout}
                    onClose={() => setShowAbout(false)}
                    selectedJurisdictionName={selectedJurisdictionName}
                    selectedJurisdictionAvailable={selectedJurisdictionAvailable}
                    selectedJurisdictionCode={selectedJurisdiction}
                  />
                )}
              </Suspense>
            </ErrorBoundary>

            {showScrollToTop && (
              <button
                onClick={handleScrollToTop}
                className="fixed bottom-6 right-6 p-3 rounded-full bg-blue-600 text-white shadow-lg hover:bg-blue-700 transition-colors z-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label="Scroll to top"
              >
                <ArrowUpCircle className="h-6 w-6" aria-hidden="true" />
              </button>
            )}

            <div className="max-w-4xl mx-auto" id="main-content">
              <header className="text-center mb-8">
                <div className="flex justify-end gap-2">
				<button
				  onClick={toggleFontSize}
				  className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
				  aria-label="Toggle Font Size"
				>
				  <span className="text-lg">{fontSize === 'normal' ? 'A+' : 'A-'}</span>
				</button>
                  <button
                    onClick={toggleTheme}
                    className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                    aria-label="Toggle Theme"
                  >
                    {theme === 'dark' ? (
                      <Sun className="h-5 w-5 text-yellow-500" aria-hidden="true" />
                    ) : (
                      <Moon className="h-5 w-5 text-gray-700" aria-hidden="true" />
                    )}
                  </button>
                </div>
				  <h1 className="text-3xl md:text-4xl font-bold mb-2 flex items-center justify-center gap-2">
					<BookOpen className="h-8 w-8" aria-hidden="true" />
					Legal Oracle
				  </h1>
					<p className="text-gray-700 dark:text-gray-400 mb-1">
					  {selectedJurisdictionAvailable
						? compareMode
						  ? `Compare ${selectedJurisdictions.map(code => 
							  JURISDICTIONS.find(j => j.code === code)?.name
							).join(' and ')} legislation`
						  : `Ask questions about ${selectedJurisdictionName} legislation`
						: `Select a jurisdiction to get started`}
					</p>
				  <StatsDisplay selectedJurisdictions={selectedJurisdictions} />
				</header>

              {/* Search Form */}
              <form onSubmit={handleSubmit} aria-label="Search form">
                <div className="relative mb-4">
                  <label htmlFor="question-input" className="sr-only">
                    Ask a question
                  </label>
                  <input
                    type="text"
                    id="question-input"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
					placeholder={
					  connected
						? selectedJurisdictionAvailable
						  ? 'Ask a question...'
						  : 'Please select an available jurisdiction'
						: 'Connecting...'
					}

                    className="w-full px-4 py-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    disabled={loading || !connected || !selectedJurisdictionAvailable}
                    autoComplete="off"
                    aria-autocomplete="list"
                    aria-expanded={suggestions.length > 0}
                    aria-owns="suggestions-list"
                  />
                  {suggestions.length > 0 && (
                    <ul
                      id="suggestions-list"
                      className="absolute z-10 w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg mt-1 max-h-60 overflow-auto focus:outline-none"
                      role="listbox"
                    >
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => handleSuggestionClick(suggestion)}
                          className="px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer text-gray-800 dark:text-gray-200"
                          role="option"
                        >
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  )}
                  <div className="absolute right-2 top-2 flex gap-2">
                    {history.length > 0 && (
                      <Tooltip.Provider>
                        <Tooltip.Root>
                          <Tooltip.Trigger asChild>
                            <button
                              type="button"
                              onClick={() => setShowHistory(!showHistory)}
                              className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                              aria-label="View History"
                            >
                              <Clock className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </Tooltip.Trigger>
                          <Tooltip.Content
                            side="left"
                            className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
                          >
                            View History
                          </Tooltip.Content>
                        </Tooltip.Root>
                      </Tooltip.Provider>
                    )}
                    <Tooltip.Provider>
                      <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                          <button
                            type="submit"
                            disabled={
                              loading ||
                              !connected ||
                              !question.trim() ||
                              !selectedJurisdictionAvailable
                            }
                            className="p-2 rounded-md bg-blue-600 hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                            aria-label="Search"
                          >
                            {loading ? (
                              <Scale className="h-5 w-5 animate-spin" aria-hidden="true" />
                            ) : (
                              <Search className="h-5 w-5" aria-hidden="true" />
                            )}
                          </button>
                        </Tooltip.Trigger>
                        <Tooltip.Content
                          side="left"
                          className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
                        >
                          Search
                        </Tooltip.Content>
                      </Tooltip.Root>
                    </Tooltip.Provider>
                  </div>
                </div>

                {/* Jurisdiction Selection */}
                <div className="mb-6">
				  {/* Beautiful Toggle */}

				  {/* JurisdictionCardSelect / question input, etc. */}
					<JurisdictionCardSelect
					  compareMode={compareMode}
					  selectedJurisdictions={selectedJurisdictions}
					  onChange={setSelectedJurisdictions}
					  setCompareMode={setCompareMode}  // Pass the toggle function
					/>
                </div>
              </form>

              {/* Error Message */}
              {error && (
                <div
                  className="bg-red-100 dark:bg-red-900/50 border border-red-300 dark:border-red-700 rounded-lg p-4 mb-6"
                  role="alert"
                >
                  <p className="text-red-700 dark:text-red-200">{error}</p>
                </div>
              )}

              {/* Connection Status */}
              {!connected && (
                <div
                  className="mt-4 bg-yellow-100 dark:bg-yellow-900/30 border border-yellow-300 dark:border-yellow-700/50 rounded-lg p-4"
                  role="status"
                >
                  <div className="flex gap-2 items-center">
                    <Loader2
                      className="h-5 w-5 animate-spin text-yellow-600 dark:text-yellow-500"
                      aria-hidden="true"
                    />
                    <p className="text-sm text-yellow-800 dark:text-yellow-200">
                      Connecting to server...
                    </p>
                  </div>
                </div>
              )}

              {/* Action Message */}
              {actionMessage && (
                <div
                  className="fixed top-4 right-4 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg animate-fadeIn"
                  role="alert"
                  aria-live="assertive"
                >
                  {actionMessage}
                </div>
              )}

              {/* History */}
              {showHistory && (
                <>
                  {history.length > 0 ? (
                    <div className="mb-8 bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg overflow-hidden animate-fadeIn">
                      <div className="p-4 border-b border-gray-300 dark:border-gray-700 flex justify-between items-center">
                        <h3 className="font-semibold">Previous Questions</h3>
                        <button
                          onClick={clearHistory}
                          className="text-sm text-red-600 dark:text-red-400 hover:text-red-500 dark:hover:text-red-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          Clear History
                        </button>
                      </div>
                      <div className="divide-y divide-gray-300 dark:divide-gray-700">
                        {history.map((item, index) => (
                          <button
                            key={index}
                            onClick={() => handleHistoryItemClick(item)}
                            className="w-full p-4 text-left hover:bg-gray-200 dark:hover:bg-gray-700/50 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            <p className="text-gray-800 dark:text-gray-300 font-medium">
                              {item.question}
                            </p>
                            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                              {new Date(item.timestamp).toLocaleString()}
                            </p>
                          </button>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <p className="text-center text-gray-600 dark:text-gray-400 mt-4">
                      No history available yet.
                    </p>
                  )}
                </>
              )}

              {/* Loading State */}
              {loading && !result && (
                <div className="text-center py-8" role="status" aria-live="polite">
                  <Scale className="h-8 w-8 animate-spin mx-auto mb-4" aria-hidden="true" />
                  <p className="text-gray-700 dark:text-gray-400">{progressMessage}</p>
                  <p className="text-sm text-gray-600 dark:text-gray-500 mt-2">
                    This may take a few moments.
                  </p>
                </div>
              )}

              {/* Results */}
              {result && (
                <div className="space-y-6 animate-fadeIn">
                  <div className="flex justify-between items-center">
                    <button
                      onClick={handleNewSearch}
                      className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <ArrowLeft className="h-4 w-4" aria-hidden="true" />
                      New Search
                    </button>
                    <div className="flex gap-2">
                      <Tooltip.Provider>
                        <Tooltip.Root>
                          <Tooltip.Trigger asChild>
                            <button
                              onClick={handleCopy}
                              className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                              title="Copy to clipboard"
                              aria-label="Copy to clipboard"
                            >
                              <Copy className="h-4 w-4" aria-hidden="true" />
                            </button>
                          </Tooltip.Trigger>
                          <Tooltip.Content
                            side="top"
                            className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
                          >
                            Copy to Clipboard
                          </Tooltip.Content>
                        </Tooltip.Root>
                      </Tooltip.Provider>
                      <Tooltip.Provider>
                        <Tooltip.Root>
                          <Tooltip.Trigger asChild>
                            <button
                              onClick={handleShare}
                              className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                              title="Share"
                              aria-label="Share"
                            >
                              <Share2 className="h-4 w-4" aria-hidden="true" />
                            </button>
                          </Tooltip.Trigger>
                          <Tooltip.Content
                            side="top"
                            className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
                          >
                            Share
                          </Tooltip.Content>
                        </Tooltip.Root>
                      </Tooltip.Provider>
                    </div>
                  </div>

                  <div className="font-medium text-lg text-gray-800 dark:text-gray-300">
                    {result.question}
                  </div>

					<FormattedAnswer
					  answer={result.answer}
					  loadingSections={loadingSections}
					  result={result}
					  sectionsConfig={sectionsConfig}
					  collapsedSections={collapsedSections}
					  onToggleCollapse={toggleCollapse}
					  wsRef={wsRef}
					  selectedScreenshot={selectedScreenshot}
					  setSelectedScreenshot={setSelectedScreenshot}
					/>

                  {loading && (
                    <div className="text-center py-4" role="status" aria-live="polite">
                      <Loader2 className="h-6 w-6 animate-spin mx-auto mb-2" aria-hidden="true" />
                      <p className="text-gray-700 dark:text-gray-400">Generating response...</p>
                    </div>
                  )}

                  <Footer setShowDisclaimer={setShowDisclaimer} setShowAbout={setShowAbout} />
                </div>
              )}

				{!result && !loading && !showHistory && (
				  <div className="mb-8 space-y-8">
					<ExampleQuestionsGrid onQuestionClick={handleExampleClick} selectedJurisdiction={selectedJurisdiction}/>
					<SEODescriptionBoxes />
					<Footer setShowDisclaimer={setShowDisclaimer} setShowAbout={setShowAbout} />
				  </div>
				)}
            </div>
          </div>
        </div>
      </FontSizeContext.Provider>
    </ThemeContext.Provider>
  );
}
